
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import SuperSlide from '@/components/common/super_slide';
  import VideoDialog from '@/components/business/video-dialog.vue';
  import Case from './case.json'
  export default {
    components: {
      Swiper,
      SwiperSlide,
      SuperSlide,
      VideoDialog
    },
    head() {
      return {
        title: '玺承集团官网首页',
        meta: [{
          hid: 'description', 
          name: 'description',
          content: '玺承，10年电商培训咨询品牌；玺承集团专注于为电商企业提供系统化培训、一对一咨询辅导、资源对接、电商圈子、国内外游学等高品质服务，多维度满足电商企业快速发展需求，给电商企业提供一站式解决方案！'
        }]
      }
    },
    data () {
      return {
        hz: 16.7,
        demandIndex: 0,
        menuY: 0,
        menuActive: 0,
        demandList: [
         {
            dataType: 'video',
            timer: null,
            type: '玺承集团',
            title: ['数字零售生态服务商'],
            tip: '教育+咨询+数智+产业+资本五大经营战略布局',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/home2023/2_xcjt.webp',
            path: '/aboutUs',
            desc: [
              '玺承集团创立于2012年，总部设于南京，客户覆盖全国30个省市自治区，在深圳、广州、武汉、南京、北京、成都、上海、香港等地设有分支机构，是中国领先的数字零售生态服务品牌。',
              '玺承围绕“教育+咨询+数智+产业+资本”五大经营战略布局，汇聚一批电商行业实战专家和服务团队，为淘宝、天猫、京东、拼多多、亚马逊、抖音、TikTok、快手、微信、小红书等互联网平台商家，提供系统化教育培训、一对一咨询辅导、商业媒体、电商垂直社区平台、品牌全案视觉、职业教育、人才服务、数字电商产业园、软件研发、高端电商圈子、国内外游学、财税股权、明星经纪、商业智能(BI)管理系统与数据运营工具软件、客服外包、产品供应链、仓储物流、投融资等高品质产品与服务。',
              '持续专注于新零售、新消费场景，布局数字零售全产业生态，为电商企业持续创造价值，全维度满足电商企业快速发展需求，给电商企业经营提供优质靠谱的一站式解决方案！',
            ]
          },
          {
            dataType: 'lamp',
            lamp: 'https://pz.xcect.com/ossImg/officialWebsite/PC/home2023/2_img_xcjy.webp',
            timer: null,
            type: '玺承教育',
            title: ['为企业提供', '全品类、全维度、全平台系统化培训服务'],
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/xc/2_xcjy.png',
            path: '/eTaoBao',
            desc: [
              '玺承教育是玺承集团旗下的教育品牌，玺承秉承“一次培训 终身服务”的理念，帮助电商企业提高运营效率，少走弯路。经过多年的积淀，目前拥有数百名电商领域实战导师，通过导师对企业的1对1咨询辅导，系统化帮助企业在市场规划、经营决策、产品开发、营销升级、渠道推广、团队建设、平台布局、财务管理等多维度提升，从而让企业实现多方位快速成长。',
              '2012年至今，累积了电商全行业的企业成长数据模型，这些数据模型多面呈现了企业从0到1000万、5000万、过亿、过十亿规模后，在发展中所遇到的问题，以及企业应对问题的经营决策和数据的量化结果。已深入服务数万家电商企业，解决了企业实际经营过程中的难题，为企业发展铺平道路，实现对企业的成长承诺。',
              '玺承精益求精的服务品质，在历届学员中赢得了广泛的赞誉，被誉为电商培训界的“黄埔军校”。玺承希望用多年的电子商务实战经验和丰富行业资源，来帮助更多的企业实现快速发展，成就这个时代有梦想的电商人!',
            ]
          },
          {
            dataType: 'lamp',
            lamp: 'https://pz.xcect.com/ossImg/officialWebsite/PC/home2023/2_img_xczx.webp',
            timer: null,
            type: '玺承咨询',
            title: ['为企业提供', '全品类、全维度、全平台深度咨询服务'],
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/xc/2_xczx.png',
            path: '/eConsultation',
            desc: [
              '玺承咨询为企业提供基于互联网零售的咨询管理服务，为互联网零售企业提供公司整体与业务单元的战略咨询，涉及企业金融、营销/销售/渠道建设、组织架构优化与变革、大数据信息技术应用、定制IT战略与项目实施、业务资源匹配等。',
              '玺承通过多年的咨询管理经验，专家顾问团队支撑，结合咨询工具和理论为企业持续成功助力。',
              '通过帮助电商企业建立行业门槛，为企业持续发展建设高墙，经过几年的发展，玺承电商俱乐部已经先后打造了上百个行业Top，更帮助上千家企业快速实现了成长突破，被誉为“电商咨询创导者”。',
            ]
          },
          {
            dataType: 'image',
            timer: null,
            type: '玺承数智',
            title: ['加速企业', '数字化建设'],
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/xc/2_xcsz.png',
            path: '/digitalIntel',
            image: 'https://pz.xcect.com/ossImg/officialWebsite/PC/home2023/2_img_xcsz.webp',
            desc: [
              '玺承数智是玺承集团旗下专为电商企业提供数字化经济建设的版块，打通整合企业全局的商业流、数据流和工作流，通过为电商零售企业实现数字化赋能，从而提升企业经营决策的精准性以及组织效率，让企业更具竞争能力；帮助企业加速数字化建设，完成数字化转型与升级，从而实现销售增长和利润增长，更高效、更创新发展。',
              '玺承运用多年沉淀的咨询管理经验，结合电商企业特性，以大数据为支撑，选用DGM电商数据增长模型研究中心、BI数字化管理系统、京东大数据调研系统、抖音大数据调研系统、拼多多大数据调研系统、亚马逊大数据调研系统等多个专业权威系统与工具，为企业可持续发展提供全方位多领域服务。',
            ]
          },
          {
            dataType: 'image',
            timer: null,
            type: '玺承产服',
            title: ['为企业提供', '全方位产业增值服务'],
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/xc/2_xccf.png',
            path: '/industry',
            image: 'https://pz.xcect.com/ossImg/officialWebsite/PC/home2023/2_img_xccf.webp',
            desc: [
              '玺承产服是玺承集团旗下为电商产业生态提供全方位产业配套资源支持的服务。',
              '通过集团的生态群，提供企业战略运营培训、战略运营咨询、企业人才孵化及输送、企业融资上市规划咨询指导、企业数字化系统建设、国内外游学、艺人经济服务、产品产业链资源对接、品牌全案视觉服务、知识产权服务、产业金融服务等全维度服务体系支撑，整合上下游企业、电商关联产业企业、服务链资源等，打造具有引领及创新标杆影响力的数字经济电商生态产业集群，提高产业服务能力，带动电商经济活力，推动新型数字电商产业发展。',
            ]
          },
          {
            dataType: 'image',
            timer: null,
            type: '玺承资本',
            title: ['为企业提供', '陪伴式投融资服务'],
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/xc/2_xczb.png',
            path: '/capitalism',
            image: 'https://pz.xcect.com/ossImg/officialWebsite/PC/home2023/2_img_xczb.webp',
            desc: [
              '玺承资本深度布局电商消费升级领域，为中早期高成长企业提供陪伴式的投融资服务。专注互联网、人工智能&大数据、消费、教育、文化娱乐、B2B&企业服务与升级等领域，投资初创期及成长期的优质创业公司。以及帮助企业对接国内多元化的中早期投资机构，包括一线美元基金，早期风险基金，国内产业型基金，大企业战略投资基金等。在交易过程中，提供交易策略建议及后台支持，帮助电商企业在顺利融资的同时，优化交易，争取企业权益及业务资源对接。',
            ]
          }
        ],
        schoolNum: [
          { num: '12', unit: '年', label: '电商生态服务经验' },
          { num: '300+', unit: '', label: '行业一线操盘手导师' },
          { num: '1800+', unit: '', label: '细分类目盈利操盘打法' },
          { num: '4000+', unit: '', label: '累计线下公开课场次' },
          { num: '50+', unit: '', label: '行业荣誉奖项' },
        ],
        schoolList: [
          {
            title: '电商生态布局',
            desc: '全品类、全维度、全平台\n助力电商发展',
          }, {
            title: '核心技术支持',
            desc: '自主研发数字IT系统\n为企业数字化赋能',
          }, {
            title: '专业咨询团队',
            desc: '300+位享誉电商界的\n一线导师智囊团',
          }, {
            title: '专业运营团队',
            desc: '500+位靠谱伙伴\n24H服务，高效协同',
          }, {
            title: '业内口碑卓越',
            desc: '12年经验沉淀\n成就12w+电商企业',
          }, {
            title: '行业权威认可',
            desc: '50+项行业荣誉奖项\n拥有自主知识产权',
          }, {
            title: '标准系统沉淀',
            desc: '万亿销售额验证的\n电商操盘系统方法论',
          }, {
            title: '高端圈子资源',
            desc: '汇集众多靠谱资源与平台\n共赢发展',
          }, {
            title: '产业集群赋能',
            desc: '全方位产业配套资源支持\n推动产业发展',
          }, {
            title: '行业权威资讯',
            desc: '智囊团独家资讯\n为企业科学决策赋能',
          }
        ],
        o3sList: [
          // {
          //   title: '全国巡回公开课',
          //   time: '3天2夜',
          //   // desc: ['适合所有电商卖家的', '系统实操课程'],
          //   desc: ['适合所有电商卖家的系统实操课程'],
          //   route: 'combatClass',
          //   icon: 'gkk'
          // },
           {
            title: '淘宝天猫盈利突破特训营',
            time: '6天6夜',
            // desc: ['王牌课程', '电商系统化培训+1v1定制化咨询'],
            desc: ['王牌课程，电商系统化培训+1v1定制化咨询'],
            route: 'eTaoBao',
            icon: 'tb'
          }, {
            title: '抖音电商盈利特训营',
            time: '5天4夜',
            // desc: ['抖音电商完整的', '商业化系统布局和实操方案'],
            desc: ['抖音电商完整的商业化系统布局和实操方案'],
            route: 'tiktok',
            icon: 'dy'
          }, {
            title: '电商财税特训营',
            time: '3天2夜',
            // desc: ['为电商老板', '专属定制的股权财税系统课程'],
            desc: ['为电商老板专属定制的财税系统课程'],
            route: 'financeClass',
            icon: 'cs'
          }, {
            title: '京东盈利增长特训营',
            time: '3天2夜',
            // desc: ['系统化掌握', '京东盈利的运营思路和方法'],
            desc: ['系统化掌握京东盈利的运营思路和方法'],
            route: 'jdCombat',
            icon: 'jd'
          }, {
            title: '拼多多盈利增长特训营',
            time: '3天2夜',
            // desc: ['搭建拼多多', '盈利模式和流量布局，快速起爆'],
            desc: ['搭建拼多多盈利模式和流量布局，快速起爆'],
            route: 'pddCombat',
            icon: 'pdd'
          }, {
            title: '快速玩转小红书成长特训营',
            time: '3天2夜',
            // desc: ['掌握平台运营策略', '快速抢占行业和市场'],
            desc: ['掌握平台运营策略，快速抢占行业和市场'],
            route: 'redBook',
            icon: 'xhs'
          }, {
            title: '亚马逊出海特训营',
            time: '2天1夜',
            // desc: ['一次性、立体式', '认识跨境电商逻辑结构'],
            desc: ['一次性、立体式认识跨境电商逻辑结构'],
            route: 'ymxCombat',
            icon: 'ymx'
          }, 
          // {
          //   title: 'TiKToK电商盈利特训营',
          //   time: '3天2夜',
          //   // desc: ['运营从0-1', '从1-100全流程运营指南'],
          //   desc: ['运营从0-1、从1-100全流程运营指南'],
          //   route: 'hwTikTok',
          //   icon: 'dy'
          // }, 
          {
            title: '1688成长特训营',
            time: '2天1夜',
            // desc: ['清晰平台精准定位', '规避运营风险'],
            desc: ['清晰平台精准定位，规避运营风险'],
            route: 'ali1688Class',
            icon: '1688'
          }, {
            title: '电商经营决策班',
            time: '3天2夜',
            // desc: ['洞察电商企业', '崛起规律，升华决策思维'],
            desc: ['洞察电商企业崛起规律，升华决策思维'],
            route: 'brandClass',
            icon: 'jyjcb'
          }, {
            title: '电商新锐品牌策划班',
            time: '3天2夜',
            // desc: ['帮助企业更高效率', '更低成本的打造新锐品牌'],
            desc: ['帮助企业更高效率更低成本的打造新锐品牌'],
            route: 'decisionClass',
            icon: 'ppchb'
          }, {
            title: '电商骨干复制方案班',
            time: '3天2夜',
            // desc: ['电商企业人岗匹配的', '绩效设计实操大课'],
            desc: ['快速打造出电商经营强团队'],
            route: 'performanceClass',
            icon: 'jxfab'
          }, {
            title: '电商新品开发策划班',
            time: '3天2夜',
            // desc: ['跨界维度打开产品思路', '建立新品塑造体系'],
            desc: ['跨界维度打开产品思路，建立新品塑造体系'],
            route: 'productClass',
            icon: 'cpchb'
          }, {
            title: '电商年度增长计划制定班',
            time: '3天2夜',
            // desc: ['10000+电商企业', '市场经验大总结'],
            desc: ['没有计划的增长是难以持续的增长'],
            route: 'growthClass',
            icon: 'dszzb'
          }, {
            title: '电商大店布局规划班',
            time: '3天2夜',
            // desc: ['跨界维度打开产品思路', '建立新品塑造体系'],
            desc: ['彻底理解一个小店如何变成大店全过程'],
            route: 'bjClass',
            icon: 'dd'
          }, {
            title: 'DISC人才识别训练班',
            time: '3天2夜',
            // desc: ['10000+电商企业', '市场经验大总结'],
            desc: ['各领域可受用一生的洞察系统'],
            route: 'discClass',
            icon: 'disc'
          }, {
            title: '金牌客服打造训练营',
            time: '2天1夜',
            // desc: ['10000+电商企业', '市场经验大总结'],
            desc: ['助力客服团队从普通到金牌的蜕变'],
            route: 'kfClass',
            icon: 'kf'
          }, {
            title: '电商私域增长方案班',
            time: '3天2夜',
            // desc: ['10000+电商企业', '市场经验大总结'],
            desc: ['电商企业私域项目全套落地方案'],
            route: 'privateDomainClass',
            icon: 'sy'
          }, {
            title: '玺承云学堂',
            time: '100+门线上课，持续更新',
            // desc: ['电商内训学堂', '全平台全岗位百门精品课程'],
            desc: ['电商内训学堂，全平台全岗位百门精品课程'],
            route: 'https://yxt.xcect.com/page/1443478?navIndex=0',
            icon: 'yxt'
          },
        ],
        isMore: false,
        clienSay: this.$DataOptions.clienSay,
        clientTypeMap: {
            '淘宝天猫': 'tx',
            '财税股权': 'cs',
            '抖音': 'dy',
            '拼多多': 'pdd',
            '小红书': 'xhs',
        },
        superList: Case,
        visible: false,
        hiddenMask: false,
        videoUrl: 'https://videostatic.xcect.com/vod-57ac9a/sv/45f7314e-1902e9d46dd/45f7314e-1902e9d46dd.mp4',
			  videoPath: '',
        clientSaySwiperOption: {
            slidesPerView: 4,
            spaceBetween: 15,
            slidesPerGroup: 1,
            loop: true,
            loopFillGroupWithBlank: true,
            navigation: {
                nextEl: '.swiper-button-next--client.mine',
                prevEl: '.swiper-button-prev--client.mine'
            }
        },
      }
    },
    async asyncData({ $api, $message }) {
      let deliverClass = []
      let openClass = []
      let dynamicList = []
      let slidesPerView2 = 5
      let slidesPerView3 = 5
      try {
        const [openClassRes, deliverClassRes, dynamicRes] = await Promise.all([
          $api.getWebSiteClassData({type:'openClass'}),
          $api.getWebSiteClassData({type:'deliverClass'}),
          $api.getWebsiteList({ fanType: '首页动态' })
        ])
        if(openClassRes?.code){
          $message.error(openClassRes.msg)
        }else{
          openClass = openClassRes
          openClass = openClass.filter(item => item.picture)
          // if (openClass.length < 5) {
          //   slidesPerView2 = openClass.length
          // }
        }
        if(deliverClassRes?.code){
          $message.error(deliverClassRes.msg)
        }else{
          deliverClass = deliverClassRes
          deliverClass = deliverClass.filter(item => item.picture)
          // if (deliverClass.length < 5) {
          //   slidesPerView3 = deliverClassRes.length
          // }
        }
        if (dynamicRes?.code) {
          $message.error(dynamicRes.msg);
        } else {
          dynamicList = dynamicRes
        }
      } catch (e) {
        console.error(e)
      }
      const swiperOption = {
        spaceBetween: 15,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        
      }
      // 课程数据
      return {
        deliverClass,
        openClass,
        dynamicList,
        swiperOption2: {
          slidesPerView: slidesPerView2,
          ...swiperOption,
          navigation: {
            nextEl: '.swiper-button-next2.mine',
            prevEl: '.swiper-button-prev2.mine'
          }
        },
        swiperOption3: {
          slidesPerView: slidesPerView3,
          ...swiperOption,
          navigation: {
            nextEl: '.swiper-button-next3.mine',
            prevEl: '.swiper-button-prev3.mine'
          }
        },
        swiperOption4: {
          slidesPerView: 'auto',
          spaceBetween: 0,
          slidesPerGroup: 1,
          loop: false,
          loopFillGroupWithBlank: true,
          navigation: {
            nextEl: '.swiper-button-next4.mine',
            prevEl: '.swiper-button-prev4.mine'
          }
        },
        swiperOption5: {
          slidesPerView: 'auto',
          spaceBetween: 0,
          slidesPerGroup: 1,
          allowTouchMove: false,
          loop: false,
          loopFillGroupWithBlank: true,
          navigation: {
            nextEl: '.iconfont-swiper-next',
            prevEl: '.iconfont-swiper-pre'
          }
        }
      }
    },
    methods: {
      playVideo (url) {
          this.videoPath = url
          this.$nextTick(() => {
              this.visible = true
          })
      },
      playVideoCover () {
          this.hiddenMask = true;
          this.$refs.video1[0].play();
      },
      openNewWindowByUrl (url) {
        window.open(url)
      },
      handleMenuClick (index) {
        this.menuActive = index
      },
      handleMoreBtnClick () {
        this.isMore = true;
        if (this.menuActive < 6) {
          this.menuActive = 6
        }
      },
      handleLessBtnClick () {
        this.isMore = false;
        if (this.menuActive >= 6) {
          this.menuActive = 0
        }
      },
      gotoProPage (item) {
        if (!item.route) return;
        if (item.route.includes('http')) {
          window.open(item.route)
        } else {
          this.$router.push({ name: item.route })
        }
      }
    }
  }
  